import { Collapse, Grid, Typography } from "@mui/material";
import classnames from "classnames";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import ExpandMore from "../expand-more";
import Image from "../image";
import { TextInput } from "../Inputs";
import QuantityInput from "../quanitity-input";
import { CardCheckboxGroup, CardCheckboxOption } from "./CardCheckboxGroup";
import { CardNumericGroup, CardNumericInput } from "./CardNumericGroup";
import {
    CardRadioButtonOption, CardRadioButtonsGroup
} from "./CardRadioButtonsGroup";
import { CardToppingOption, CardToppingSelect } from "./CardToppingSelect";
import * as styles from "./index.module.scss";

const Card = ({
  children,
  style,
  classNames,
  animateError,
  hasErrors,
  appStyles,
  kioskMode,
  refEl,
  flat,
  ...props
}) => {
  const CardStyle = appStyles && appStyles.Card;
  return (
    <div
      ref={refEl}
      className={classnames(
        styles.Card,
        classNames,
        animateError && styles.AnimateError,
        flat && styles.Flat,
      )}
      style={{
        ...CardStyle,
        ...style,
        ...(hasErrors && {
          border: `solid ${kioskMode ? 5 : 2}px ${appStyles.accentColor}`,
        }),
      }}
      {...props}
    >
      {children}
    </div>
  );
};

Card.Image = ({
  mediaKey,
  mediaType,
  sizes,
  imagePreview,
  alt = "",
  wrapperStyle,
  imgStyle,
  className,
  noImageRenderer: NoImageRenderer,
  background = "#eee",
}) => (
  <div
    className={classnames(styles.CardImage, className)}
    style={{ ...(!_.isEmpty(mediaKey) && { background }) }}
  >
    {!_.isEmpty(mediaKey) ? (
      <Image
        imgStyle={imgStyle}
        mediaKey={mediaKey}
        mediaType={mediaType}
        sizes={sizes}
        imagePreview={imagePreview}
        alt=""
        wrapperStyle={wrapperStyle}
        style={{ width: "100%", position: "static" }}
      />
    ) : (
      <NoImageRenderer />
    )}
  </div>
);

Card.Title = ({
  children,
  light,
  small,
  spread,
  appStyles,
  classNames,
  style,
}) => {
  const { CardTitle = {}, rtl } = appStyles;
  return (
    <h2
      className={classnames(
        styles.CardTitle,
        light && styles.Light,
        small && styles.Small,
        spread && styles.Spread,
        rtl && styles.RTL,
        classNames,
      )}
      style={{ ...CardTitle, ...style }}
    >
      {children}
    </h2>
  );
};

Card.Description = ({ children, style, classNames }) => (
  <p className={classnames(styles.CardDescription, classNames)} style={style}>
    {children}
  </p>
);

Card.Header = ({ children, style, classNames, onClick = _.noop }) => (
  <div
    className={classnames(styles.CardHeader, classNames)}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

Card.Header.Title = ({ children, appStyles = {}, classNames, style }) => {
  const { CardTitle = {} } = appStyles;
  return (
    <div
      className={classnames(styles.CardHeaderTitle, classNames)}
      style={{ ...style, ...CardTitle }}
    >
      {children}
    </div>
  );
};

Card.Header.SideNote = ({
  children,
  style,
  classNames,
  color,
  appStyles = {},
}) => {
  const { CardSideNote = {} } = appStyles;
  return (
    <div
      className={classnames(styles.CardHeaderSideNote, classNames)}
      style={{ ...style, color, ...CardSideNote }}
    >
      {children}
    </div>
  );
};

Card.Content = ({ children, style, classNames, tightTop, tight }) => (
  <div
    className={classnames(
      styles.CardContent,
      tight && styles.Tight,
      tightTop && styles.TightTop,
      classNames,
    )}
    style={style}
  >
    {children}
  </div>
);

Card.Segment = ({
  children,
  style,
  classNames,
  title,
  sideNote,
  refEl = _.noop,
  expandedByDefault = false,
  summaryText,
}) => {
  const [expanded, setExpanded] = useState(expandedByDefault);

  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);

  return (
    <div
      ref={refEl}
      className={classnames(styles.CardSegment, classNames)}
      style={style}
    >
      <Grid
        item
        container
        direction="column"
        className={styles.CardSegmentTitle}
        onClick={toggleExpanded}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={1}
        >
          <Grid item>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              wrap="nowrap"
            >
              {sideNote && (
                <Grid item>
                  <small style={{ flexShrink: 0 }}>{sideNote}</small>
                </Grid>
              )}
              <Grid item>
                <ExpandMore
                  expanded={expanded}
                  handleExpandClick={toggleExpanded}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Collapse in={!expanded}>
            {summaryText && <small>{summaryText}</small>}
          </Collapse>
        </Grid>
      </Grid>
      <Collapse in={expanded}>
        <div className={styles.CardSegmentContent}>{children}</div>
      </Collapse>
    </div>
  );
};

Card.Note = (props) => <TextInput classNames={styles.CardNote} {...props} />;

Card.QuantityInput = (props) => {
  return (
    <div className={classnames(styles.QuantityInputWrapper)}>
      <QuantityInput {...props} />
    </div>
  );
};

Card.Content.RadioGroup = CardRadioButtonsGroup;
Card.Content.RadioOption = CardRadioButtonOption;
Card.Content.CheckboxGroup = CardCheckboxGroup;
Card.Content.CheckboxOption = CardCheckboxOption;
Card.Content.ToppingSelect = CardToppingSelect;
Card.Content.ToppingOption = CardToppingOption;
Card.Content.CardNumericInput = CardNumericInput;
Card.Content.CardNumericGroup = CardNumericGroup;

export default Card;
